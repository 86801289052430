import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Check, X } from 'react-feather'
import { ArrowRight } from 'react-feather'

import Logo from '../components/logo-svg'

import FacebookIcon from '../assets/icons/facebook'
import InstagramIcon from '../assets/icons/instagram'
import GoogleIcon from '../assets/icons/google'
import YoutubeIcon from '../assets/icons/youtube'
import GuildqualityIcon from '../assets/icons/guildquality'
import FooterAreasServedList from './footer-areas-served'

import { useHiddenmsClass } from '../helpers/useHiddenMatch'

const LegalLinks = styled.div`
  margin-top: 5px;
  a {
    color: #757575;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.9em;
    &:hover {
    }
  }
`
const AreasServedUl = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 50%;
    a {
      color: #444 !important;
    }
  }
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 4px;
`
const LogoSVG = styled.div`
  max-width: 235px;
  padding-bottom: 35px;
`
const FooterOuterContainer = styled.div`
  background: #fff;
  padding: 3em 0;
  font-size: 0.9em;
  a {
    color: #444;
  }
  h4 {
    color: #cc0001;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  li {
    margin-bottom: calc(1.2rem / 2);
  }
  @media (max-width: 992px) {
    padding: 0.8em 0;
  }
`
const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  padding-top: 1em;
  a {
    color: #757575;
  }
`
const CopyrightTextContainer = styled.div`
  color: #757575;
  text-align: center;
  margin: 0;
  padding: 0.5em;
  font-size: 0.8em;
`

const CopyrightText = styled.p``
const SidebarHeader = styled.h5`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const FooterLinksMenu = styled.ul`
list-style-type: none;
margin-left:0px;
li {
  list-style-type: none;
  padding: 0;
  padding-left:25px;
  line-height: 1.4;
  position:relative;
  a {
    color:#444;
  }
    svg {
      border-radius:50%;
      padding-top:5px;
      stroke-width: 3px;
      margin-left:-27px;
      padding:2px;
      position:absolute;
      top:1px;
    }`

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;

  li {
    color: #444;
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 3px;
      margin-left: -27px;
      padding: 2px;
      position: absolute;
      top: 1px;
    }
  }
`

const ServicesUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    color: #444;
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 3px;
      margin-left: -30px;
      padding: 4px;
      position: absolute;
      top: 2px;
    }
  }
`

const SocialMediaIcon = styled.div`
  path {
    fill: #444;
  }
  a {
    svg {
      height: 24px;
    }
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;
  padding: 1em 0;
`

const CompanyInfoColumn = (props) => {
  let { siteMetadata } = props
  const footerRefs = useHiddenmsClass({
    // this is the action that will be performed when all conditions are met for the hiddenMS class
    // it works as or, so either value in the array list
    urlParamsToMatch: ['campaigns=ads'],
    handler: (ref) => {
      const className = ref.current.classList
      ref.current.className =
        className.length > 0 ? className + ' hiddenms' : 'hiddenms'
    },
  })
  // servicePage boolean takes precedence over the footerRefs on service-cities template
  const { servicePage } = props

  const addressRef = useRef()
  footerRefs.push(addressRef)
  return (
    <>
      <LogoSVG alt={'US Quality Construction Logo'}>
        <Logo />
      </LogoSVG>
      {siteMetadata.companyInfo.license ? (
        <div>
          <SidebarHeader>Licensed Contractor</SidebarHeader>
          <SidebarP>License #: {siteMetadata.companyInfo.license}</SidebarP>
        </div>
      ) : null}

      <SidebarHeader>Contact Us</SidebarHeader>
      <SidebarP>{siteMetadata.companyInfo.phone}</SidebarP>
      {!servicePage && (
        <div ref={addressRef}>
          <SidebarHeader>Main Office</SidebarHeader>
          <SidebarP>
            {siteMetadata.companyInfo.addressLine1}
            <br />
            {siteMetadata.companyInfo.city}, {siteMetadata.companyInfo.state}{' '}
            {siteMetadata.companyInfo.zip}
          </SidebarP>
        </div>
      )}
    </>
  )
}

const WhyUs = (props) => {
  return (
    <>
      <h4>Why Us</h4>
      <WhyUsUnorderedList>
        {props.siteMetadata.whyUs.map((item, index) => (
          <li key={index}>
            <Check size={22} className="footerlist" />
            {item}
          </li>
        ))}
      </WhyUsUnorderedList>
    </>
  )
}

const AreasServed = (props) => {
  let { serviceArea } = props
  return (
    <>
      <h4>Areas Served</h4>
      <AreasServedUl>
        {serviceArea.edges.map((item, index) => (
          <li key={index}>
            <Link
              to={'/siding-' + item.node.slug + '/'}
              title={
                item.node.location +
                ', ' +
                item.node.stateAbbreviation +
                ' Siding Company'
              }
            >
              {item.node.location}
            </Link>
          </li>
        ))}
      </AreasServedUl>
      <Link to={'/cities/'}>
        <i>View Full Service Area</i>
      </Link>
    </>
  )
}
const FooterColumms = (footerData) => {
  const footerColumns = footerData.data.map((footer, index) => (
    <FooterColumm
      key={index}
      footerInfo={footer}
      columnsCount={footerData.data.length}
      siteData={footerData.siteData.site}
      serviceArea={footerData.siteData.allContentfulServiceArea}
      servicePage={footerData.servicePage}
    />
  ))
  return footerColumns
}

const FooterColumm = (footerInfo) => {
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{CompanyInfo}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <CompanyInfoColumn
          siteMetadata={footerInfo.siteData.siteMetadata}
          servicePage={footerInfo.servicePage}
        />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{WhyUs}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{AreasServed}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <AreasServed serviceArea={footerInfo.serviceArea} />
      </div>
    )
  }

  if (footerInfo.footerInfo.node.menuLinks) {
    const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map(
      (menuItem, index) => (
        <li key={index}>
          <ArrowRight size={22} className="footerlist" />

          <Link
            to={
              menuItem.url
                ? menuItem.url[0] !== '/'
                  ? '/' + menuItem.url
                  : menuItem.url
                : '#'
            }
            title={menuItem.title}
            activeClassName={'active'}
          >
            {menuItem.title}
          </Link>
        </li>
      )
    )
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <h4>{footerInfo.footerInfo.node.title}</h4>
        <FooterLinksMenu>{footerLinksMapped}</FooterLinksMenu>
      </div>
    )
  }

  return (
    <div
      className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
      style={{
        order:
          footerInfo.footerInfo &&
          footerInfo.footerInfo.node &&
          footerInfo.footerInfo.node.order,
      }}
    >
      <h4>{footerInfo.footerInfo.node.title}</h4>test
      {footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: footerInfo.footerInfo.node.html.html,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const Footer = ({ isHomePage = false, servicePage }) => {
  const areasServedPopupOpenRef = useRef()
  const [areasServedPopupOpen, setAreasServedPopupOpen] = useState(false)
  const toggleReviewsModal = (state) => {
    setAreasServedPopupOpen(state)
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        toggleReviewsModal(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  useOnClickOutside(areasServedPopupOpenRef, () => toggleReviewsModal(false))

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              title
              whyUs
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
              social {
                yelp
                facebook
                instagram
                youtube
                google
                guildquality
              }
            }
          }
          allContentfulFooterFooterColumn {
            edges {
              node {
                id
                title
                order
                menuLinks {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                html {
                  html
                }
              }
            }
          }
          allContentfulServiceArea(
            filter: { featuredCity: { eq: true } }
            sort: { fields: location, order: ASC }
          ) {
            edges {
              node {
                location
                id
                slug
                stateAbbreviation
              }
            }
          }
          remodelingCitiesQuery: allContentfulServiceArea(
            sort: { fields: location, order: ASC }
          ) {
            edges {
              node {
                location
                slug
                stateAbbreviation
              }
            }
          }
        }
      `}
      render={function (data) {
        //assuming length of array equals columns intended
        let footerData = data.allContentfulFooterFooterColumn.edges

        let { siteMetadata } = data.site
        return (
          <FooterOuterContainer className="container-fluid foottablet">
            <div
              className={`modal-bg-overlay ${
                areasServedPopupOpen ? 'active' : null
              }`}
            />
            <div
              className={` overflow-y-scroll  h-full standard-modal ${
                areasServedPopupOpen ? 'active' : null
              }`}
              ref={areasServedPopupOpenRef}
            >
              <div className="flex items-center justify-between px-8 py-6">
                <div className="font-semibold text-black text-2xl">
                  Areas Served
                </div>
                <button
                  type="button"
                  onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
                  className="border-0 bg-white hover:gvd-main-bg-darker cursor-pointer rounded-full w-10 h-10 flex text-black items-center justify-center"
                >
                  {' '}
                  <X size={28} />
                </button>
              </div>
              <div className="px-8">
                <FooterAreasServedList />
              </div>
            </div>
            <div className="container">
              <div className="row">
                <FooterColumms
                  data={footerData}
                  siteData={data}
                  servicePage={servicePage}
                />
              </div>
            </div>

            <SocialMediaIcons>
              {siteMetadata.social.facebook ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.facebook}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <FacebookIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.instagram ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.instagram}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <InstagramIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.google ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.google}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <GoogleIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.youtube ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.youtube}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <YoutubeIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}
              {siteMetadata.social.guildquality ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.guildquality}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <GuildqualityIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}
            </SocialMediaIcons>

            <CopyrightContainer>
              <CopyrightTextContainer>
                <CopyrightText>
                  &copy; {new Date().getFullYear()}{' '}
                  {data.site.siteMetadata.title}
                  , Inc. All Rights Reserved. <br />
                  Serving Kansas City, Central-Missouri and surrounding areas.
                  Contractor Lic. # LC001476684. <br />
                  <span
                    itemScope
                    className="rating-desc"
                    itemType="https://schema.org/Product"
                  >
                    <span itemProp="name">{data.site.siteMetadata.title}</span>
                    <span
                      itemScope
                      itemProp="aggregateRating"
                      itemType="https://schema.org/AggregateRating"
                    >
                      {' '}
                      rated <span itemProp="ratingValue">4.9</span> / 5 based on{' '}
                      <span itemProp="reviewCount">201</span> reviews.
                    </span>
                  </span>{' '}
                  {isHomePage ? (
                    <div>
                      <span>
                        {' '}
                        Web Design &amp; Marketing by{' '}
                        <a target="_blank" href="https://www.webperfex.com/?utm_source=usqualityconstruction&utm_medium=referral">
                          WebPerfex
                        </a>
                      </span>
                    </div>
                  ) : null}
                </CopyrightText>
                <LegalLinks>
                <Link
                    to={'/press-releases/'}
                    title={'Read our press releases'}
                  >
                    Press Releases
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to={'/privacy-policy/'}
                    title={'Learn about our commitment to privacy'}
                  >
                    Privacy Policy
                  </Link>
                  &nbsp;&nbsp;
                  <Link to={'/contact/'} title={'Contact Us'}>
                    Contact Us
                  </Link>
                </LegalLinks>
              </CopyrightTextContainer>
            </CopyrightContainer>
            <div class="container">
              <CopyrightContainer>
                <CopyrightText className="footercities">
                  US Quality Construction proudly serves all of the following
                  cities: &nbsp;
                  {data.remodelingCitiesQuery.edges.map(
                    (
                      { node: { id, location, slug, stateAbbreviation } },
                      i
                    ) => (
                      <React.Fragment key={id}>
                        <Link
                          to={`/siding-${slug}/`}
                          title={`Siding ${location} ${stateAbbreviation}`}
                        >
                          {location}
                        </Link>
                        {(i < data.remodelingCitiesQuery.edges.length - 2
                          ? ', '
                          : '') +
                          (i == data.remodelingCitiesQuery.edges.length - 2
                            ? ' and '
                            : '')}
                      </React.Fragment>
                    )
                  )}
                  <span
                    className="cursor-pointer hover:text-black"
                    onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
                  >
                    - See All Service Area
                  </span>
                </CopyrightText>
              </CopyrightContainer>
            </div>
          </FooterOuterContainer>
        )
      }}
    />
  )
}

export default Footer
